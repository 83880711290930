import * as React from "react";
import {
  IconButton,
  Badge,
  Stack,
  Typography,
  Box,
  useTheme,
} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import NotificationsIcon from "@mui/icons-material/Notifications";
import WebSocketInstance from "../../utils/WebSocketInstance";
import { useEffect } from "react";
import { useContext } from "react";
import AuthContext from "../../context/Auth/AuthContext";
import useWebSocket from "../../hooks/useWebSocket";
import { useState } from "react";
import useHttpRequest from "../../hooks/useHttpRequest";
import CheckIcon from "@mui/icons-material/Check";
import { tokens } from "../../theme/theme";
import { LoadingButton } from "@mui/lab";


const ITEM_HEIGHT = 48;

export default function Notifications() {
  const [notifications, setNotifications] = useState({
    count: 0,
    page_size: 10,
    page: 1,
    results: [],
  });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const colors = tokens();
  const { user, authState } = useContext(AuthContext);
  const theme = useTheme();
  const { messages } = useWebSocket(
    `wss://monasabatyemeniah.com/ws/notifications/?${user.user_id}`
  );
  
  const open = Boolean(anchorEl);

  const {
    sendRequest: sendGetNotificationsRequest,
    status: getNotificationsRequestStatus,
    error: getNotificationsRequestError,
  } = useHttpRequest();

  const {
    sendRequest: sendSetSeenNotificationsRequest,
    status: setSeenNotificationsRequestStatus,
    error: setSeenNotificationsRequestError,
  } = useHttpRequest();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

    sendSetSeenNotificationsRequest(
      {
        url: "api/notifications/set-seen",
        data: { ids: notifications.results?.map((not) => not.id) },
        method: "PUT",
      },
      (data) => {}
    );
  };

  const handleViewMore = () => {
    setNotifications((prev) => ({ ...prev, page_size: prev.page_size + 10 }));
  };

  const handleClose = () => {
    setAnchorEl(null);
    setNotifications({
      ...notifications,
      results: notifications.results.map((notification) => {
        notification.seen = true;
        return notification;
      }),
    });
  };

  useEffect(() => {
    setNotifications((prev) => ({
      ...prev,
      results: [...prev.results, ...messages].sort(function (a, b) {
        return new Date(b.created_at) - new Date(a.created_at);
      }),
    }));
  }, [messages]);

  useEffect(() => {
    sendGetNotificationsRequest(
      {
        url: `api/notifications/?page_size=${notifications.page_size}&page=${notifications.page}`,
      },
      (data) => {
        setNotifications((prev) => ({
          ...prev,
          results: data.results,
          count: data.count,
        }));
      }
    );
  }, [
    sendGetNotificationsRequest,
    user.user_id,
    notifications.page_size,
    notifications.page,
  ]);

  return (
    <Stack
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Badge
          badgeContent={
            notifications.results?.filter((not) => !not.seen)?.length
          }
          color="secondary"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          horizontal: "center",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 7,
            // width: '50ch',
          },
        }}
      >
        {notifications.results?.map((notification, index) => (
          <MenuItem
            divider={true}
            dense={true}
            key={index}
            // selected={option === 'Pyxis'}
            onClick={handleClose}
          >
            <Stack flexDirection="row" gap={2}>
              <Stack>
                <Typography sx={{ fontSize: "16px" }}>
                  {notification.message}
                </Typography>
                <Typography sx={{ fontSize: "12px" }}>
                  {notification.created_at}
                </Typography>
              </Stack>

              <Stack justifyContent="center" alignItems="center">
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    backgroundColor: notification.seen
                      ? colors.secondary[100]
                      : colors.primary[200],
                    borderRadius: "100%",
                  }}
                ></Box>
              </Stack>
            </Stack>
          </MenuItem>
        ))}
        {notifications.count > notifications.page_size && (
          <LoadingButton
            loading={getNotificationsRequestStatus === "loading"}
            type="submit"
            variant="outlined"
            fullWidth
            onClick={handleViewMore}
          >
            اظهر المزيد
          </LoadingButton>
        )}
      </Menu>
    </Stack>
  );
}
