import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import { Box, Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import DashboardSidebar from "./DashboardSidebar";
import { useCallback } from "react";
const Layout = ({ children }) => {
  const ref = useRef();
  const [width, setWidth] = useState(0);
  const location = useLocation();
  const rootPath = location.pathname.split("/")[1];

  const resize = useCallback((e)=>{
     setWidth(ref.current.offsetWidth);
  },[])
  useEffect(() => {
    if (rootPath === "dashboard") {
      setWidth(ref.current.offsetWidth);
      window.addEventListener("resize", resize);
    } else {
      window.removeEventListener("resize", resize);
    }
  }, [rootPath, resize]);

  return (
    <>
      {rootPath === "dashboard" ? (
        <>
          <Box ref={ref}>
            <Navbar />
          </Box>
          <Box
            flexDirection="row"
            sx={{ display: "flex", justifyContent: "start", maxWidth: width }}
          >
            <DashboardSidebar />
            <main style={{ maxWidth: width - 100 }}>{children}</main>
          </Box>
          <Footer />
        </>
      ) : (
        <>
          <Navbar />
          <Stack flexDirection="row" sx={{}}>
            {rootPath === "dashboard" ? <DashboardSidebar /> : <></>}
            <main style={{ flex: 1 }}>{children}</main>
          </Stack>
          <Footer />
        </>
      )}
    </>
  );
};

export default Layout;
