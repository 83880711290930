import {
  ListItem,
  Typography,
  IconButton,
  ButtonBase,
  Container,
  useMediaQuery,
  Avatar,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import theme, { tokens } from "../../theme/theme";
import { AuthContext } from "../../context/Auth/AuthContext";
import PhoneIcon from "@mui/icons-material/Phone";
import FacebookIcon from "@mui/icons-material/Facebook";
import MTButton from "../UI/MTButton";
import styled from "@emotion/styled";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material";
import FullScreenDialog from "./Dialog";
import Notifications from "../notifications/notifications";
import { WhatsApp } from "@mui/icons-material";

export const headerItems = [
  { id: 5, item: "رئيسية", link: "/" },
  { id: 4, item: "قاعات", link: "/halls" },
  { id: 3, item: "استراحات", link: "/isterahat" },
  { id: 2, item: "عن مناسبات", link: "/about" },
  { id: 1, item: "تواصل", link: "/contact" },
];

export const NavItem = styled((props) => (
  <ButtonBase component={NavLink} disableRipple elevation={0} {...props} />
))(({ theme }) => ({
  fontSize: "18px",
  textTransform: "uppercase",
  fontWeight: "400",
  color: theme.palette.text.primary,
  fontFamily: "Alexandria, sans-serif",
  width: "max-content",
  p: "0",
  "&:hover": {
    color: `#FFF !important`,
  },
  textAlign: "center",
}));

const UpperNavbar = () => {
  const colors = tokens();
  const openNewTab = (url) => window.open(url, "_blank");

  const style = {
    upperPart: {
      height: "30px",
      display: "flex",
      flexDirection: "row-reverse",
      justifyContent: "space-between",
      alignItems: "center",
      backgroundColor: "#F8F9FA",
    },

    iconsBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      gap: { xs: "10px", md: "20px" },
    },

    iconStyle: {
      fontSize: { xs: "20px" },
      color: theme.palette.text.primary,
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  };

  return (
    <Box sx={{ backgroundColor: colors.secondary[50], width: "100%" }}>
      <Container>
        <Box sx={style.upperPart}>
          <Box sx={style.iconsBox}>
            <IconButton
              sx={{ p: 0 }}
              disableRipple
              onClick={() =>
                openNewTab("https://api.whatsapp.com/send?phone=967776114646")
              }
            >
              <WhatsApp sx={style.iconStyle} />
            </IconButton>
            <IconButton
              sx={{ p: 0 }}
              onClick={() =>
                openNewTab(
                  "https://www.facebook.com/profile.php?id=100095743711466"
                )
              }
            >
              <FacebookIcon sx={style.iconStyle} />
            </IconButton>
          </Box>
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              color={theme.palette.text.primary}
              sx={{ color: theme.palette.text.primary, fontSize: "16px" }}
            >
              <a
                style={{ textDecoration: "none", color: "black" }}
                href={`tel:967776114646+`}
              >
                967776114646+
              </a>
            </Typography>
            <PhoneIcon
              sx={{ color: theme.palette.primary.main, fontSize: "18px" }}
            />
          </Stack>
        </Box>
      </Container>
    </Box>
  );
};

const Navbar = () => {
  const colors = tokens();
  const theme = useTheme();
  const navigate = useNavigate();
  const isMatch = useMediaQuery(theme.breakpoints.down("md"));
  const { authState, logoutUser, user } = useContext(AuthContext);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const avatarMenu = (
    <div>
      <Button onClick={handleClick}>
        <Avatar alt="" />
      </Button>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <MenuItem
          onClick={() => {
            navigate("profile");
            handleClose();
          }}
          value="profile"
        >
          الصفحة الشخصية
        </MenuItem>
        {user?.is_staff && (
          <MenuItem
            onClick={() => {
              navigate(`dashboard/${user?.subscriper_uuid}`);
              handleClose();
            }}
            value="dashboard"
          >
            لوحة التحكم
          </MenuItem>
        )}
        <MenuItem
          onClick={() => {
            logoutUser();
            handleClose();
          }}
        >
          خروج
        </MenuItem>
      </Menu>
    </div>
  );

  return (
    <Stack width="100%" direction="column">
      <Box sx={{ backgroundColor: colors.secondary[50], width: "100%" }}>
        <UpperNavbar />
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.primary.main,
          width: "100%",
          p: "5px 0",
        }}
      >
        <Container>
          <NavLink />
          <Stack
            width="100%"
            direction="row-reverse"
            alignItems="center"
            justifyContent="space-between"
          >
            {isMatch ? (
              <Stack flexDirection="row">
                {!authState ? (
                  <MTButton
                    sx={{ p: "0px 20px" }}
                    disableRipple
                    component={Link}
                    to={"/login"}
                  >
                    تسجيل
                  </MTButton>
                ) : (
                  <Stack sx={{ display: "flex", flexDirection: "row" }}>
                    <Notifications />
                    {avatarMenu}
                  </Stack>
                )}
                <FullScreenDialog />
              </Stack>
            ) : (
              <>
                {!authState ? (
                  <MTButton
                    sx={{ p: "0px 20px", fontFamily: "Alexandria, sans-serif" }}
                    disableRipple
                    component={Link}
                    to={"/login"}
                  >
                    تسجيل
                  </MTButton>
                ) : (
                  <Stack sx={{ display: "flex", flexDirection: "row" }}>
                    <Notifications />
                    {avatarMenu}
                  </Stack>
                )}
                <Stack
                  sx={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    left: { md: "10%", lg: "20%", xl: "20%" },
                  }}
                >
                  {headerItems.map((newHeaderItem) => {
                    return (
                      <ListItem key={newHeaderItem.id}>
                        <NavItem to={newHeaderItem.link} sx>
                          {newHeaderItem.item}
                        </NavItem>
                      </ListItem>
                    );
                  })}
                </Stack>
              </>
            )}

            <ButtonBase disableRipple sx={{ left: 0 }} component={Link} to="/">
              <Typography variant="h4" sx={{ fontSize: "28px" }}>
                مناسبات
              </Typography>
            </ButtonBase>
          </Stack>
        </Container>
      </Box>
    </Stack>
  );
};

export default Navbar;
