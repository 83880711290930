import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import theme from "./theme/theme";

import { ProSidebarProvider } from "react-pro-sidebar";
import { AuthProvider } from "./context/Auth/AuthContext";
import PlaceToReserveProvider from "./context/PlaceToReserve";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    <PlaceToReserveProvider>
      <ProSidebarProvider>
        <BrowserRouter>
          <AuthProvider>
            {/* <CssBaseline /> */}
            <App />
          </AuthProvider>
        </BrowserRouter>
      </ProSidebarProvider>
    </PlaceToReserveProvider>
  </ThemeProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


