import jwt_decode from "jwt-decode";

export const authInitialState = {
  authState: localStorage.getItem("monasabat_tokens")
    ? JSON.parse(localStorage.getItem("monasabat_tokens"))
    : null,
  isLoading: false,
  error: null,
  user: localStorage.getItem("monasabat_tokens")
    ? jwt_decode(JSON.parse(localStorage.getItem("monasabat_tokens")).access)
    : null,
};

export const authActions = {
  SUCCESS_LOGIN: "SUCCESS_LOGIN",
  START_LOGIN: "START_LOGIN",
  FAIL_LOGIN: "FAIL_LOGIN",
  LOGOUT: "LOGOUT",
  REFRESH_TOKEN: "REFRESH_TOKEN",
};

export function authReducer(state, action) {
  switch (action.type) {
    case authActions.START_LOGIN:
    return {
      ...state,
      isLoading:true
    }
    case authActions.SUCCESS_LOGIN:
      const authState = action.payload.authState;
      localStorage.setItem("monasabat_tokens", JSON.stringify(authState));
      return {
        ...state,
        authState,
        user:jwt_decode(authState.access),
        isLoading: false,
      };
    case authActions.FAIL_LOGIN:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    case authActions.LOGOUT:
      localStorage.removeItem("monasabat_tokens");
      return {
        ...state,
        user:null,
        authState: null,
      };
    case authActions.REFRESH_TOKEN:
      const tokens = action.payload
      localStorage.setItem("monasabat_tokens", JSON.stringify(tokens));
      return{
        state,
        user: jwt_decode(tokens.access),
        authState:tokens,
      }  
    default:
      return state;
  }
}
