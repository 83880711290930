import React, { createContext } from "react";
import { useReducer } from "react";
import { authActions, authInitialState, authReducer } from "./AuthUtils";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { placeToReserveContext } from "../PlaceToReserve";
import { baseURL } from "../../hooks/useAxios";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [authState, dispatch] = useReducer(authReducer, authInitialState);
  const navigate = useNavigate();
  const { link } = useContext(placeToReserveContext);
  let data = {};
  let loginUser = async ({ email, password }) => {
    dispatch({
      type: authActions.START_LOGIN,
      payload: {},
    });
    try {
      let response = await fetch(`${baseURL}api/auth/login/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: email,
          password: password,
        }),
      });
      
      data = await response.json();

      if (response.ok) {
        dispatch({
          type: authActions.SUCCESS_LOGIN,
          payload: { authState: data },
        });

        if (link) {
          navigate(link);
        } else {
          navigate("/");
        }
      } else {
        throw Error();
      }
    } catch (e) {

      dispatch({ type: authActions.FAIL_LOGIN, payload: data });
    }
  };

  let logoutUser = () => {
    dispatch({ type: authActions.LOGOUT });
    navigate("/login");
  };

  let contextData = {
    authState: authState.authState,
    loginUser: loginUser,
    authDispatch: dispatch,
    logoutUser,
    authError: authState.error,
    user: authState.user,
    isLoading: authState.isLoading,
  };

  return (
    <AuthContext.Provider value={contextData}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
