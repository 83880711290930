import React from "react";
import { useState } from "react";
import { createContext } from "react";

export const placeToReserveContext = createContext();

const PlaceToReserveProvider = ({children}) => {
  const [link, setLink] = useState();


  return (
    <placeToReserveContext.Provider
      value={{
        link,
        setLink,
      }}
    >
      {children}
    </placeToReserveContext.Provider>
  );
};

export default PlaceToReserveProvider;
