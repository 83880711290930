import React from "react";
import theme from "../../theme/theme";

import {
  Box,
  List,
  ListItem,
  Typography,
  Stack,
  Container,
  IconButton,
  ButtonBase,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import InstagramIcon from "@mui/icons-material/Instagram";
import TelegramIcon from "@mui/icons-material/Telegram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Link } from "react-router-dom";
import { WhatsApp } from "@mui/icons-material";

export default function Footer() {
  const openNewTab = (url) => window.open(url, "_blank");

  const footerItems = [
    { id: 1, item: "رئيسية", link: "/" },
    { id: 2, item: "عن مناسبات", link: "/about" },
    { id: 3, item: "تواصل", link: "/contact" },
  ];

  const footerStyles = {
    iconsBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-around",
      width: "200px",
      pt: "10px",
    },

    iconStyle: {
      fontSize: "30px",
      color: theme.palette.primary.main,
      "&:hover": {
        color: "#FFF",
      },
    },

    listText: {
      fontSize: "20px",
      textTransform: "uppercase",
      fontWeight: "600",
      color: "#fff",
      "&:hover": {
        color: theme.palette.primary.main,
      },
      fontFamily: theme.typography.fontFamily,
    },

    listItem: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-evenly",
      alignItems: "flex-start",
      textAlign: "left",
      color: "#FFFFFF",
    },

    contactsStyle: {
      width: "100%",
      display: "flex",
      flexDirection: "row",
      gap: "20px",
      justifyContent: { xs: "center", md: "right" },
      alignItems: "center",
      pr: "20px",
      "&:hover": {
        color: theme.palette.primary.main,
      },
    },
  };

  const newFooterItems = footerItems.map((newFooterItem) => (
    <ListItem sx={footerStyles.listItem} key={newFooterItem.id}>
      <ButtonBase
        component={Link}
        disableRipple
        to={newFooterItem.link}
        sx={footerStyles.listText}
      >
        {newFooterItem.item}
      </ButtonBase>
    </ListItem>
  ));

  return (
    <Stack
      height={{ xs: "100%", lg: "370px" }}
      position="static"
      justifyContent="space-between"
      sx={{
        boxSizing: "border-box",
        backgroundColor: theme.palette.secondary.main,
        color: "#FFFFFF",
        mt: "50px",
      }}
    >
      <Container maxWidth="xl">
        <Stack
          direction={{ xs: "column", md: "row", lg: "row" }}
          justifyContent={{
            xs: "",
            sm: "",
            md: "space-evenly",
            lg: "space-evenly",
          }}
          alignItems={{
            xs: "center",
            sm: "",
            md: "flex-start",
            lg: "flex-start",
          }}
          spacing={{ xs: "20px" }}
          p={{ xs: "50px", lg: "30px 0 0" }}
        >
          <Box
            width="200px"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Typography
              textAlign="center"
              fontWeight="700"
              fontSize={{ xs: "48px", lg: "60px" }}
              letterSpacing="1px"
            >
              مناسبات
            </Typography>
            <Typography fontSize="14px" textAlign="center">
              مناسبات - حيث كل مناسبة تجد مكانها المناسب{" "}
            </Typography>

            <Box sx={footerStyles.iconsBox}>
              <IconButton
                onClick={() =>
                  openNewTab("https://api.whatsapp.com/send?phone=967776114646")
                }
              >
                <WhatsApp sx={footerStyles.iconStyle} />
              </IconButton>
              <IconButton
                onClick={() =>
                  openNewTab(
                    "https://www.facebook.com/profile.php?id=100095743711466"
                  )
                }
              >
                <FacebookIcon sx={footerStyles.iconStyle} />
              </IconButton>
            </Box>
          </Box>

          <Box sx={{ pt: "15px", m: "0" }}>
            <List>
              <Typography
                fontSize="24px"
                color={theme.palette.primary.main}
                textAlign={{ xs: "center", md: "right" }}
                justifySelf={{ xs: "center", md: "flex-end" }}
                alignSelf={{ xs: "center", md: "flex-end" }}
                pb="5px"
              >
                روابط :
              </Typography>
              {newFooterItems}
            </List>
          </Box>

          <Box
            sx={{
              maxWidth: "300px",
              maxHeight: "250px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              justifyContent: "center",
              alignItems: "center",
              pt: "25px",
            }}
          >
            <Typography
              fontSize="24px"
              color={theme.palette.primary.main}
              textAlign={{ xs: "center", md: "right" }}
              justifySelf={{ xs: "center", md: "flex-end" }}
              alignSelf={{ xs: "center", md: "flex-start" }}
            >
              تواصل :{" "}
            </Typography>
            <Box component="span" sx={footerStyles.contactsStyle}>
              {" "}
              <PhoneEnabledIcon />{" "}
              <Typography>
                <a
                  style={{ textDecoration: "none", color: "white" }}
                  href={`tel:967776114646+`}
                >
                  967776114646+
                </a>
              </Typography>
            </Box>
            <Box component="span" sx={footerStyles.contactsStyle}>
              <EmailIcon />{" "}
              <a
                href="mailto:monasabatyemeniah@example.com"
                style={{ textDecoration: "none", color: "white" }}
              >
                <Typography sx={{ fontSize: { xs: "14px", md: "16px" } }}>
                  monasabatyemeniah@gmail.com
                </Typography>
              </a>
            </Box>
          </Box>
        </Stack>
      </Container>
      <Box
        sx={{
          backgroundColor: theme.palette.text.primary,
          height: "70px",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
      >
        <Typography variant="h6" color="#FFFFFF">
          {" "}
          صُمِم الموقع و طُوِر عن طريق{" "}
        </Typography>
        <Typography variant="h6" color={theme.palette.primary.main}>
          <a
            href="http://ceresoftware.co"
            style={{
              textDecoration: "none",
              color: theme.palette.primary.main,
            }}
          >
            cereSoftware{" "}
          </a>
        </Typography>
      </Box>
    </Stack>
  );
}
