import React from "react";
import { useContext } from "react";
import AuthContext from "../context/Auth/AuthContext";
import { Navigate, Outlet } from "react-router-dom";

const SubscripersOnly = () => {
  const { user } = useContext(AuthContext);

  return user?.is_staff && !user?.is_superuser ? (
    <Outlet />
  ) : (
    <Navigate to="/" />
  );
};

export default SubscripersOnly;
