import Layout from "./components/layout/layout";
import ScrollToTop from "./utils/ScrollToTop";
import { Route, Routes } from "react-router-dom";

import UnauthenticatedOnly from "./protected-routes/UnauthenticatedOnly";
import AuthenticatedOnly from "./protected-routes/AuthenticatedOnly";

import { Helmet } from "react-helmet";
import SubscripersOnly from "./protected-routes/SubscripersOnly";
import { Suspense, lazy } from "react";
import { CircularProgress, Stack, useTheme } from "@mui/material";

import PropagateLoader from 'react-spinners/PropagateLoader'

const Home = lazy(() => import("./pages/home/index"));
const Halls = lazy(() => import("./pages/halls"));
const Isterahas = lazy(() => import("./pages/isterahas"));
const About = lazy(() => import("./pages/about"));
const Contact = lazy(() => import("./pages/contact"));
const Login = lazy(() => import("./pages/login"));
const Register = lazy(() => import("./pages/register"));
const HallDetails = lazy(() => import("./pages/hall-details"));
const IsterahaDetails = lazy(() => import("./pages/isteraha-detials"));
const DBMain = lazy(() => import("./pages/dashboard-halls/DashboardMain"));
const DBIMain = lazy(() => import("./pages/dashboard-isterahat/DBIMain"));
const User = lazy(() => import("./pages/user/User"));
const NotFoundPage = lazy(() => import("./pages/notFound/notFoundPage"));
const Recovery = lazy(() => import("./pages/login/RecRest/indexRecover"));
const Reset = lazy(() => import("./pages/login/RecRest/inedexReset"));
const DashboardMain = lazy(() => import("./pages/dashboard/dashboard"));
const Resend = lazy(() => import("./pages/login/RecRest/Resend"));



const LoadingState = () => {
  const theme = useTheme()
  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      height="80vh"
      width="90vw"
    >
      <PropagateLoader size={30} color={theme.palette.primary.main} />
    </Stack>
  );
};
function App() {
  return (
    <Layout>
      <Helmet>
        <title>مناسبات</title>
        <link rel="icon" type="image/png" href="favicon.ico" sizes="16x16" />
      </Helmet>
      <ScrollToTop>
        <Routes>
          <Route
            path="/"
            element={
              <Suspense fallback={<LoadingState />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="/about"
            element={
              <Suspense fallback={<LoadingState />}>
                <About />
              </Suspense>
            }
          />
          <Route
            path="/contact"
            element={
              <Suspense fallback={<LoadingState />}>
                <Contact />
              </Suspense>
            }
          />
          <Route element={<UnauthenticatedOnly />}>
            <Route
              path="/login"
              element={
                <Suspense fallback={<LoadingState />}>
                  <Login />
                </Suspense>
              }
            />
            <Route
              path="/resend"
              element={
                <Suspense fallback={<LoadingState />}>
                  <Resend />
                </Suspense>
              }
            />
            <Route
              path="/register"
              element={
                <Suspense fallback={<LoadingState />}>
                  <Register />
                </Suspense>
              }
            />
            <Route
              path="/password/reset/"
              element={
                <Suspense fallback={<LoadingState />}>
                  <Recovery />
                </Suspense>
              }
            />
            <Route
              path="/password/reset/confirm/:uid/:token"
              element={
                <Suspense fallback={<LoadingState />}>
                  <Reset />
                </Suspense>
              }
            />
          </Route>
          <Route element={<AuthenticatedOnly />}>
            <Route
              path="/profile"
              element={
                <Suspense fallback={<LoadingState />}>
                  <User />
                </Suspense>
              }
            />
          </Route>
          <Route path="/halls">
            <Route
              index
              element={
                <Suspense fallback={<LoadingState />}>
                  <Halls />
                </Suspense>
              }
            />
            <Route
              path="/halls/:id"
              element={
                <Suspense fallback={<LoadingState />}>
                  <HallDetails />
                </Suspense>
              }
            />
          </Route>
          <Route path="/isterahat">
            <Route
              index
              element={
                <Suspense fallback={<LoadingState />}>
                  <Isterahas />
                </Suspense>
              }
            />
            <Route
              path="/isterahat/:id"
              element={
                <Suspense fallback={<LoadingState />}>
                  <IsterahaDetails />
                </Suspense>
              }
            />
          </Route>
          <Route element={<SubscripersOnly />}>
            <Route path="/dashboard">
              <Route
                path="/dashboard/:id"
                element={
                  <Suspense fallback={<LoadingState />}>
                    <DashboardMain />
                  </Suspense>
                }
              />
              <Route
                path="/dashboard/halls/:id"
                element={
                  <Suspense fallback={<LoadingState />}>
                    <DBMain />
                  </Suspense>
                }
              />
              <Route
                path="/dashboard/isterahat/:id"
                element={
                  <Suspense fallback={<LoadingState />}>
                    <DBIMain />
                  </Suspense>
                }
              />
            </Route>
          </Route>
          <Route
            path="*"
            element={
              <Suspense fallback={<LoadingState />}>
                <NotFoundPage />
              </Suspense>
            }
          />
        </Routes>
      </ScrollToTop>
    </Layout>
  );
}

export default App;
