import {
  Container,
  Dialog,
  IconButton,
  ListItem,
  Paper,
  Slide,
  Stack,
  useTheme,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, { useState } from "react";
import { NavItem, headerItems } from "./Navbar";
import { tokens } from "../../theme/theme";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const FullScreenDialog = () => {
  const theme = useTheme();
  const colors = tokens();
  const [isOpen, setIsOpen] = useState(false);

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };
  return (
    <div>
      <IconButton
        onClick={handleOpen}
        sx={{ color: theme.palette.secondary.main }}
      >
        <MenuIcon />
      </IconButton>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <Stack sx={{ backgroundColor: colors.secondary[800] }} height="100vh">
          <Container>
            <Stack alignItems="end">
              <IconButton
                onClick={() => setIsOpen(!isOpen)}
                sx={{ color: "white" }}
              >
                <MenuIcon />
              </IconButton>
            </Stack>
          </Container>
          <Stack alignItems="center" justifyContent="center">
            <Stack
              sx={{
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
                left: { md: "10%", lg: "20%", xl: "20%" },
              }}
            >
              {headerItems.map((newHeaderItem) => {
                return (
                  <ListItem
                    sx={{ display: "flex", justifyContent: "center" }}
                    onClick={handleClose}
                    key={newHeaderItem.id}
                  >
                    <NavItem
                      key={newHeaderItem.id}
                      to={newHeaderItem.link}
                      sx={{ color: "white !important" }}
                    >
                      {newHeaderItem.item}
                    </NavItem>
                  </ListItem>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </Dialog>
    </div>
  );
};

export default FullScreenDialog;
