import axios from "axios";
import jwt_decode from "jwt-decode";
import dayjs from "dayjs";
import { useContext, useMemo } from "react";
import AuthContext from "../context/Auth/AuthContext";
import { authActions } from "../context/Auth/AuthUtils";

export const baseURL = "https://monasabatyemeniah.com/";

const useAxios = () => {
  const { authState, authDispatch, user } = useContext(AuthContext);

  const axiosInstance = useMemo(
    () =>
      axios.create({
        baseURL,
        headers: authState?.access
          ? { authorization: `Bearer ${authState?.access}` }
          : {},
      }),
    [authState?.access]
  );

  axiosInstance.interceptors.request.use(async (req) => {
    if (!user) return req;
    const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;

    if (!isExpired) return req;


    try {
      const response = await axios.post(
        `${baseURL}api/token/refresh/`,
        {
          refresh: authState.refresh,
        },
        { headers: { "Content-Type": "application/json" } }
      );


      authDispatch({ type: authActions.REFRESH_TOKEN, payload: response.data });

      req.headers.Authorization = `Bearer ${response.data.access}`;
    } catch (e) {
      authDispatch({ type: authActions.LOGOUT });
    }


    return req;
  });

  return axiosInstance;
};

export default useAxios;
