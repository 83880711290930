import styled from "@emotion/styled";
import { alpha, Button } from "@mui/material";
import React from "react";

const MTButton = styled((props) => <Button {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  // fontWeight: 400,
  fontSize: "20px",
  color: theme.palette.text.primary,
  // padding: "24px 54px",
  borderRadius: "0",
  textDecoration: "uppercase",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  "&:disabled": {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export default MTButton;
