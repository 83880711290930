import { IconButton } from "@mui/material";
import React from "react";
import {
  Sidebar,
  useProSidebar,
  MenuItem,
  Menu,
  SubMenu,
} from "react-pro-sidebar";

import MenuIcon from "@mui/icons-material/Menu";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import useHttpRequest from "../../hooks/useHttpRequest";
import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import AuthContext from "../../context/Auth/AuthContext";
import FestivalIcon from "@mui/icons-material/Festival";
import StorefrontIcon from "@mui/icons-material/Storefront";

const DashboardSidebar = () => {
  const [halls, setHalls] = useState([]);
  const [isterahat, setIsterahat] = useState([]);
  const { collapsed, collapseSidebar } = useProSidebar();
  const { user, authState } = useContext(AuthContext);


  const theme = useTheme();
  const handleToggleSidebar = () => {
    collapseSidebar(!collapsed);
  };

  const {
    sendRequest: sendGetOwnedHallsRequest,
    status: getOwnedHallsRequestStatus,
    error: getOwnedHallsRequestError,
  } = useHttpRequest();

  const style = { backgroundColor: theme.palette.secondary.main };

  useEffect(() => {
    if (getOwnedHallsRequestStatus === "idle") {
      sendGetOwnedHallsRequest(
        { url: `api/subscripers/${user?.subscriper_uuid}/sidebar` },
        (data) => {
          setHalls(data.halls);
          setIsterahat(data.isterahat);
        }
      );
    }
  }, [
    getOwnedHallsRequestStatus,
    sendGetOwnedHallsRequest,
    user?.subscriper_uuid,
  ]);

  return (
    <Sidebar
      rootStyles={{ backgroundColor: "white" }}
      width="200px"
      rtl={true}
      defaultCollapsed
    >
      <Menu>
        <MenuItem>
          <IconButton onClick={handleToggleSidebar}>
            <MenuIcon />
          </IconButton>
        </MenuItem>
        <SubMenu label="قاعات" icon={<FestivalIcon />}>
          {halls?.map((item, index) => {
            const hallPath = `dashboard/halls/${item.uuid}`;
            return (
              <MenuItem key={index} component={<Link to={hallPath} />}>
                {item.name}
              </MenuItem>
            );
          })}
        </SubMenu>
        <SubMenu label="استراحات" icon={<StorefrontIcon />}>
          {isterahat?.map((item, index) => {
            return (
              <MenuItem
                key={index}
                component={<Link to={`dashboard/isterahat/${item.uuid}`} />}
              >
                {item.name}
              </MenuItem>
            );
          })}
        </SubMenu>
      </Menu>
    </Sidebar>
  );
};

export default DashboardSidebar;
